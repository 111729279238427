import * as React from 'react';
import { Badge, Icon } from 'antd';

const NotificationIcon = () => (
  <Badge count={0}>
    <Icon type="bell" />
  </Badge>
);

export default NotificationIcon;
