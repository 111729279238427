/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import cx from 'clsx';
import { apiFetch, getResource } from '@ucentric/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formatter, url as urlHelper } from '@ucentric/utils';
import { withI18n } from 'react-i18next';
import { get } from 'lodash';

import config from 'config';
import styles from './styles.module.css';
import { CustomerAccountContractSummaryPanelOuterProps, CustomerAccountContractSummaryPanelProps } from './properties';

export const CustomerAccountContractSummaryPanel = ({
  action,
  contracts,
  rawContracts,
  t,
}: CustomerAccountContractSummaryPanelProps) => {
  const totalOutstandingBalance = contracts.reduce<MonetaryAmount>(
    (finalOutstandingBalance, contract) => {
      // @note if backend want to return OutstandingBalance, make sure to return MonetaryAmount ATTACH with currency,
      // and all MonetaryAmount is using SAME country currency, if not, it will break
      const outstandingBalanceValue = get(contract, 'billingSummary.outstandingBalance.value');
      const outstandingBalanceCurrency = get(contract, 'billingSummary.outstandingBalance.currency');

      if (Number(outstandingBalanceValue) > 0 && !!outstandingBalanceCurrency) {
        if (!!finalOutstandingBalance.currency && outstandingBalanceCurrency !== finalOutstandingBalance.currency) {
          throw new Error(`Country currency of contract's outstanding balance is different!`);
        }

        return {
          currency: outstandingBalanceCurrency,
          value: (Number(outstandingBalanceValue) + Number(finalOutstandingBalance.value)).toString(),
        };
      } else if (Number(outstandingBalanceValue) > 0 && !outstandingBalanceCurrency) {
        throw new Error(`OutstandingBalance 'Value' found but country currency is not attached!`);
      } else {
        return finalOutstandingBalance;
      }
    },
    { currency: null, value: null },
  );

  React.useEffect(() => {
    if (rawContracts.some((contract) => urlHelper.isIri(contract))) {
      rawContracts.forEach((contractIri) => {
        action.apiFetch(contractIri, undefined, {
          subrequests:
            config.PROFILE !== 'unionpower'
              ? ['billingInformation', 'consumptionHistories', 'financialHistories']
              : ['billingSummary'],
        });
      });
    }
  }, [rawContracts]);

  return (
    <div>
      <span className={styles.item}>
        <b className={styles.label}>Total Contracts:</b> <br />
        <span className={styles.text}>
          {contracts.length} {t('common:contract', { count: contracts.length }).toLowerCase()}
        </span>
      </span>

      <span className={styles.item}>
        <b className={styles.label}>Total Outstanding Balance:</b> <br />
        <span className={cx(styles.text, styles.price)}>
          {/* @todo dont think backend will bother this issue, so for front-end will temporary return $0.00 if no outstandingBalance.value found*/}
          {Number(totalOutstandingBalance.value) > 0 ? formatter.formatMoney(totalOutstandingBalance) : '$0.00'}
        </span>
      </span>
    </div>
  );
};

const mapStateToProps = (state: any, { contracts }: CustomerAccountContractSummaryPanelProps) => ({
  contracts: contracts
    .map((contract) =>
      getResource<Contract>(
        state,
        'contracts',
        { '@id': urlHelper.irify(contract) },
        {
          subresources:
            config.PROFILE !== 'unionpower'
              ? ['billingInformation', 'consumptionHistories', 'financialHistories']
              : ['billingSummary'],
        },
      ),
    )
    .filter((contract) => contract && contract.contractNumber),
  rawContracts: contracts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
    },
    dispatch,
  ),
});

export default compose<CustomerAccountContractSummaryPanelProps, CustomerAccountContractSummaryPanelOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withI18n(),
  React.memo,
)(CustomerAccountContractSummaryPanel);
