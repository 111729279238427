/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import { ApiRoute } from '@ucentric/constants';
import { GetDataArgs } from 'found';
import { apiFetch, getManagerCustomerAccount, requestAuthTokenSuccess } from '@ucentric/redux';
import { apiFilter, apiUtils } from '@ucentric/utils';

import { CONTRACT_SUBRESOURCE_PATHS, OFFER_CATALOG_SUBRESOURCE_PATHS, TICKET_SUBRESOURCE_PATHS } from 'app/constants';

export function fetchAffiliateProgram(meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
    params: { id },
  }: GetDataArgs) => dispatch(apiFetch(`${ApiRoute.AffiliateProgram}/${id}`, undefined, { ...meta, subrequests: ['image'] }));
}

export function fetchAffiliatePrograms(filter?: {}, meta?: {}) {
  return ({
    context: {
      store: { dispatch },
    },
  }: GetDataArgs) =>
    dispatch(
      apiFetch(
        ApiRoute.AffiliateProgram,
        { ...filter, order: { dateCreated: 'desc' }, itemsPerPage: 150, ...apiFilter.AFFILIATE_PROGRAM_ACTIVE_FILTER },
        { ...meta, subrequests: ['image'] },
      ),
    );
}

export function registerLogin() {
  return ({
    context: {
      store: { dispatch },
    },
    location: { query },
  }: GetDataArgs) => {
    return dispatch(
      requestAuthTokenSuccess(query.token),
    );
  }
}

export function fetchTicket(filter?: {}, meta?: {}) {
  return async (props: GetDataArgs) => {
    const {
      context: {
        store: { dispatch },
      },
    } = props;
    const customerAccount = await fetchLoggedInUserCustomerAccount(props);

    return dispatch(
      apiFetch(
        ApiRoute.Ticket,
        {
          customer: customerAccount['@id'],
          itemsPerPage: 10,
          order: { dateCreated: 'desc' },
          ...filter,
        },
        { ...meta, subrequests: TICKET_SUBRESOURCE_PATHS },
      ),
    );
  };
}

export async function fetchContract(props: GetDataArgs) {
  const {
    context: {
      store: { dispatch },
    },
    params: { contractNumber },
  } = props;

  let contract = dispatch(
    apiFetch(
      ApiRoute.Contract,
      { contractNumber, itemsPerPage: 1 },
      {
        subrequests: ['actions', 'actions.instrument', 'actions.object', 'actions.result'],
      },
    ),
  );
  if (contract) {
    contract = apiUtils.getActiveContract(contract);
    contract = dispatch(
      apiFetch(
        ApiRoute.Contract,
        { contractNumber, itemsPerPage: 1 },
        {
          subrequests: CONTRACT_SUBRESOURCE_PATHS,
        },
      ),
    );
  }

  return contract;
}

export async function fetchForCreditsListPage(props: GetDataArgs) {
  const {
    context: {
      store: { dispatch, getState },
    },
  } = props;
  // const loggedInUser = getLoggedInUser(getState()) as User;
  const customerAccount = await fetchLoggedInUserCustomerAccount(props);

  Promise.all([fetchContracts()(props)]);
  return dispatch(
    apiFetch(customerAccount['@id'], undefined, {
      subrequests: ['moneyCreditsActions', 'pointCreditsActions'],
    }),
  );
}

export function fetchContracts(filter?: {}, meta?: {}) {
  return async (props: GetDataArgs) => {
    const {
      context: {
        store: { dispatch, getState },
      },
    } = props;
    const customerAccount = await fetchLoggedInUserCustomerAccount(props);
    const managerCustomerAccount = getManagerCustomerAccount(getState());

    return dispatch(
      apiFetch(
        ApiRoute.Contract,
        {
          customer: managerCustomerAccount ? managerCustomerAccount['@id'] : customerAccount['@id'],
          itemsPerPage: 30,
          order: { dateCreated: 'desc' },
          ...filter,
        },
        { ...meta, subrequests: ['addresses'] },
      ),
    );
  };
}

export async function fetchLoggedInUserCustomerAccount({
  context: {
    store: { dispatch, getState },
  },
}) {
  const user: User = await dispatch(apiFetch(`${ApiRoute.User}/${getState().auth.sub}`));
  const customerAccount: CustomerAccount = await dispatch(
    apiFetch(user.customerAccount['@id'], undefined, {
      subrequests: [
        'contracts',
        'corporationDetails',
        'corporationDetails.contactPoints',
        'corporationDetails.identifiers',
        'personDetails',
        'personDetails.contactPoints',
        'personDetails.identifiers',
        'relationships',
        'relationships.from',
        'relationships.to',
      ],
    }),
  );

  return customerAccount;
}

export function fetchInvitePage(props: GetDataArgs) {
  const {
    context: {
      store: { dispatch },
    },
  } = props;

  // Fetch RF credits scheme
  return dispatch(apiFetch(ApiRoute.CreditsScheme, { 'isBasedOn[exists]': false, schemeId: 'RF' }));
}

export function fetchOfferCatalog(meta?: {}) {
  return async ({
    context: {
      store: { dispatch },
    },
    location: { query },
    params: { id },
  }: GetDataArgs) => {
    if (query.contract) {
      await dispatch(apiFetch(ApiRoute.Contract, { contractNumber: query.contract }));
    }

    return dispatch(
      apiFetch(`${ApiRoute.OfferCatalog}/${id}`, undefined, { ...meta, subrequests: OFFER_CATALOG_SUBRESOURCE_PATHS }),
    );
  };
}
