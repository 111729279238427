/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import { Layout } from 'antd';
import { EnvironmentWarningAlert, ErrorBoundary } from '@ucentric/react-components';
import { useToggle } from 'react-use';

import MainHeader from 'app/components/layouts/MainHeader';
import ManagingOtherAccountAlert from 'app/components/commons/ManagingOtherAccountAlert';
import SiderMenu from 'app/components/layouts/SiderMenu';
import SwitchAccountDrawer from 'app/components/drawers/SwitchAccountDrawer';
// import StatusProgressBar from 'app/components/common/StatusProgressBar';
import config from 'config';
import styles from './styles.module.css';
import { AppProps } from './properties';

const App = ({ children }: AppProps) => {
  const [isSwitchAccountDrawerOpen, toggleSwitchAccountDrawer] = useToggle(false);
  const [isSiderMenuCollapsed, toggleSiderMenu] = useToggle(false);

  return (
    <>
      <ManagingOtherAccountAlert />

      <Layout className={styles.root}>
        <SiderMenu collapsed={isSiderMenuCollapsed} collapsible trigger={null} className={styles.siderMenu} />

        <Layout>
          <Layout.Header className={styles.header}>
            <MainHeader
              collapsed={isSiderMenuCollapsed}
              onCollapseChange={() => toggleSiderMenu()}
              onSwitchAccountClick={() => toggleSwitchAccountDrawer()}
            />
          </Layout.Header>

          <Layout.Content>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Layout.Content>

          <Layout.Footer style={{ textAlign: 'center' }}>
            {config.COMPANY.NAME} &copy; {new Date().getFullYear().toString()} Powered by {config.COPYRIGHT}
          </Layout.Footer>
        </Layout>
      </Layout>

      <SwitchAccountDrawer
        onChange={() => toggleSwitchAccountDrawer(false)}
        onClose={() => toggleSwitchAccountDrawer(false)}
        visible={isSwitchAccountDrawerOpen}
      />

      {config.ENABLE_ENVIRONMENT_WARNING_ALERT && <EnvironmentWarningAlert />}
    </>
  );
};

export default App;
