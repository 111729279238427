import config from 'config';

export const APPLICATION_REQUESTS_SUBRESOURCE_PATHS = ['advisoryNotice', 'advisoryNotice.file'];

export const CONTRACTS_SUBRESOURCE_PATHS = ['addresses', 'actions', 'tariffRate'];

export const CONTRACT_SUBRESOURCE_PATHS = [
  'actions',
  'actions.instrument',
  'actions.object',
  'actions.result',
  'addresses',
  'customer',
  'customer.addresses',
  'customer.personDetails',
  'customer.personDetails.contactPoints',
  'tariffRate',
  'welcomePackages',
  ...(config.PROFILE !== 'unionpower'
    ? ['billingInformation', 'consumptionHistories', 'financialHistories']
    : ['billingSummary']),
];

// TODO ADD back webservice for Union

export const OFFER_CATALOG_SUBRESOURCE_PATHS = [
  'coverImage',
  'itemListElement',
  'itemListElement.item',
  'itemListElement.item.category',
  'itemListElement.item.image',
  'itemListElement.item.seller',
];

export const OFFER_CATALOGS_SUBRESOURCE_PATHS = ['image'];

export const TICKET_SUBRESOURCE_PATHS = ['category', 'creator', 'subcategory', 'type'];
