/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import { AccountType } from '@ucentric/constants';
import { Alert } from 'antd';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formatter } from '@ucentric/utils';
import { getCurrentManagingCustomerAccount, getLoggedInUser } from '@ucentric/redux';

import { ManagingOtherAccountAlertProps } from './properties';
import { RootState } from 'app/redux/store';

export const ManagingOtherAccountAlert = ({ otherAccount }: ManagingOtherAccountAlertProps) =>
  !!otherAccount ? (
    <Alert
      message={`You are currently managing ${
        otherAccount.type === AccountType.Individual
          ? formatter.formatName(otherAccount.personDetails)
          : otherAccount.corporationDetails.name
      }'s Account. `}
      style={{ textAlign: 'center' }}
      type="warning"
    />
  ) : null;

const mapStateToProps = (state: RootState) => {
  const loggedInUser = getLoggedInUser(state);
  const currentManagingCustomerAccount = getCurrentManagingCustomerAccount(state);
  const isManagingOtherAccount =
    !!currentManagingCustomerAccount && !!loggedInUser
      ? currentManagingCustomerAccount['@id'] !== loggedInUser.customerAccount['@id']
      : undefined;

  return {
    otherAccount: isManagingOtherAccount ? currentManagingCustomerAccount : undefined,
  };
};

export default compose(
  connect(mapStateToProps),
  React.memo,
)(ManagingOtherAccountAlert);
