import * as React from 'react';
import { AccountCategory } from '@ucentric/constants';
import { LoadingPanel } from '@ucentric/react-components';
import { RedirectException } from 'found';
import { logout } from '@ucentric/redux';
import { Modal } from 'antd';
import config from 'config';
import { get } from 'lodash';

import { fetchLoggedInUserCustomerAccount } from 'app/helpers/router-fetch';

class CustomerCategoryCheckRoute {
  public getData = async (props) => {
    const state = props.context.store.getState();
    let loggedInUser;

    if (!state.auth || !state.auth.token) {
      this.forceLogout(props);
    }

    try {
      loggedInUser =
        props.location.pathname === '/' || props.location.pathname === '/applications/create'
          ? await fetchLoggedInUserCustomerAccount(props)
          : undefined;
    } catch (error) {
      this.forceLogout(props);
    }

    return loggedInUser;
  };

  public render({ data, match }) {
    if (match.location.pathname === '/') {
      if (!data) {
        return <LoadingPanel />;
      }

      if (!data.categories.includes(AccountCategory.Customer) && !data.categories.includes(AccountCategory.ContactPerson)) {
        throw new RedirectException('/shop');
      }
    }

    if (match.location.pathname === '/applications/create') {
      if (!data) {
        return <LoadingPanel />;
      }

      if (!!get(data, 'dateBlacklisted')) {
        Modal.error({
          title: 'Error',
          content: (
            <div>
              <p>
                We apologise for the inconvenience. The application cannot be processed. Please contact us by clicking{' '}
                <a href={config.COMPANY.CONTACT_US_URL} target="_blank">
                  here
                </a>
                .
              </p>
            </div>
          ),
        });
        throw new RedirectException('/applications');
      }
    }
  }

  private forceLogout = (props) => {
    props.context.store.dispatch(logout());

    throw new RedirectException('/logout');
  };
}

export default CustomerCategoryCheckRoute;
