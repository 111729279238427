/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import shallowEqual from 'shallowequal';
import cx from 'clsx';
import { ApiRoute, Source } from '@ucentric/constants';
import { TicketTable } from '@ucentric/react-components';
import { Card } from 'antd';
import { Link } from 'found';
import {
  apiFetch,
  getLoggedInUser,
  getPaginationByIri,
  getResources,
  getResourcesByIri,
  getCurrentManagingCustomerAccount,
} from '@ucentric/redux';
import { apiSearchQuery } from '@ucentric/utils';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';

import config from 'config';
// import newCaseEnquiryButtonImageUrl from 'app/assets/images/btn-enquiry.png';
// import newCaseFeedbackButtonImageUrl from 'app/assets/images/btn-feedback.png';
import styles from './styles.module.css';
import { RootState } from 'app/redux/store';
import { TICKET_SUBRESOURCE_PATHS } from 'app/constants/api-subresource-paths';
import { TicketListPageOuterProps, TicketListPageProps } from './properties';

function makeTicketSearchQuery(query: {}) {
  return {
    ...apiSearchQuery.ticketSearchQuery(query),
    itemsPerPage: 10,
    order: { dateCreated: 'desc' },
  };
}

class TicketListPage extends React.Component<TicketListPageProps> {
  private get searchQuery() {
    return {
      ...makeTicketSearchQuery(this.props.location.query),
      creator: this.props.loggedInUser['@id'],
      customer: !!this.props.managerCustomerAccount
        ? this.props.managerCustomerAccount['@id']
        : this.props.loggedInUser.customerAccount['@id'],
    };
  }

  public componentDidMount() {
    if (this.props.loggedInUser) {
      this.props.action.apiFetch(ApiRoute.Ticket, this.searchQuery, {
        subrequests: TICKET_SUBRESOURCE_PATHS,
      });
      this.props.action.apiFetch(ApiRoute.TicketType, { usedIn: [Source.SelfServicePortal] });
    }
  }

  public componentDidUpdate(prevProps: TicketListPageProps) {
    if (
      (!shallowEqual(prevProps.location.query, this.props.location.query) ||
        !shallowEqual(prevProps.loggedInUser, this.props.loggedInUser)) &&
      !!this.props.loggedInUser
    ) {
      this.props.action.apiFetch(ApiRoute.Ticket, this.searchQuery, {
        subrequests: TICKET_SUBRESOURCE_PATHS,
      });
    }
  }

  public render() {
    const { tickets, location, pagination, status, t } = this.props;

    return (
      <div className={styles.root}>
        <Card className={styles.card} title={t('common:contactUs')}>
          {config.PROFILE === 'iswitch' && (
            <div>
              <p className={styles.decription}>
                Have a question? Need some help? Drop us message by clicking ‘FEEDBACK’ and we will be happy to assist you.
              </p>
            </div>
          )}
          <div className={styles.actionsPanel}>
            {this.props.ticketTypes &&
              this.props.ticketTypes.map(
                (type) =>
                  type.enabled && (
                    <Link key={type['@id']} to={`/cases/create?type=${type.name}`} activeClassName="active">
                      {this.renderButtonDesign(type)}
                    </Link>
                  ),
              )}
          </div>

          {/*
          <Link to="/cases/create?type=enquiry" activeClassName="active">
            <img className={styles.image} src={newCaseEnquiryButtonImageUrl} />
          </Link>
          <Link to="/cases/create?type=feedback" className={styles.link} activeClassName="active">
            <img className={styles.image} src={newCaseFeedbackButtonImageUrl} />
          </Link>
          */}
        </Card>
        <Card className={styles.card} title={t('common:myCase_plural')}>
          <TicketTable
            dataSource={tickets}
            ineligibleColumns={['actions', 'assignee', 'customerDetails', 'priority', 'slaBreach', 'status']}
            loading={status.pending > 0}
            pagination={
              pagination
                ? {
                    current: location.query.page ? parseInt(location.query.page, 10) : undefined,
                    onChange: this.handlePaginationChange,
                    pageSize: this.searchQuery.itemsPerPage,
                    total: pagination.totalItems,
                  }
                : undefined
            }
          />
        </Card>
      </div>
    );
  }

  private handlePaginationChange = (page: number) => {
    this.props.router.push({
      ...this.props.location,
      query: {
        ...this.props.location.query,
        page,
      },
    });
  };

  private renderButtonDesign = (type: TicketType) => {
    const ticketTypeName = type.name.toLowerCase();
    const isEnquiry = ticketTypeName.includes('enquiry') || ticketTypeName.includes('enquiries');
    const isFeedback = ticketTypeName.includes('feedback');
    const isComplaint = ticketTypeName.includes('complaint');

    return (
      <span
        className={cx(styles.button, {
          [styles.primary]: isEnquiry,
          [styles.secondary]: isFeedback,
          [styles.tertiary]: isComplaint,
        })}
      >
        <span>
          {isEnquiry && (
            <>
              MAKE AN <b>ENQUIRY</b> NOW
            </>
          )}
          {isFeedback && (
            <>
              <b>FEEDBACK</b>
              <br />
              {config.PROFILE !== 'iswitch' && (
                <div>
                  <b>HOW ARE WE</b>
                  <br />
                  <b>DOING</b>
                </div>
              )}
            </>
          )}
          {isComplaint && (
            <>
              LET US KNOW YOUR <b>COMPLAINT</b>
            </>
          )}

          {!isEnquiry && !isFeedback && !isComplaint && type.name}
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state: RootState, { location: { query } }: TicketListPageProps) => {
  const loggedInUser: User = getLoggedInUser(state, { subresources: [] });
  const managerCustomerAccount: CustomerAccount = getCurrentManagingCustomerAccount(state);

  const searchQuery = {
    ...makeTicketSearchQuery(query),
    creator: loggedInUser['@id'],
    customer: !!managerCustomerAccount ? managerCustomerAccount['@id'] : loggedInUser.customerAccount['@id'],
  };

  const tickets = getResourcesByIri<Ticket>(state, ApiRoute.Ticket, searchQuery, undefined, {
    subresources: TICKET_SUBRESOURCE_PATHS,
  });

  return {
    loggedInUser,
    managerCustomerAccount,
    pagination: getPaginationByIri(state, ApiRoute.Ticket, searchQuery),
    status: state.statuses,
    ticketTypes: getResources(state, 'ticketTypes', { usedIn: [Source.SelfServicePortal] }),
    tickets,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
    },
    dispatch,
  ),
});

export default compose<TicketListPageProps, TicketListPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withI18n(),
)(TicketListPage);
