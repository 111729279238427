import * as React from 'react';
import { connect } from 'formik';
import { compose } from 'recompose';

import TicketSummaryTable from 'app/components/tables/TicketSummaryTable';
import { TicketSummaryFieldsetOuterProps, TicketSummaryFieldsetProps } from './properties';

export const TariffRateSummaryFieldset = ({ translationOptions }: TicketSummaryFieldsetProps) => {
  return <TicketSummaryTable translationOptions={translationOptions}/>;
};

export default compose<TicketSummaryFieldsetProps, TicketSummaryFieldsetOuterProps>(connect)(TariffRateSummaryFieldset);
