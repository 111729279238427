/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import { AccountCategory, ContractType } from '@ucentric/constants';
import { Icon, Layout, Menu } from 'antd';
import { Link } from 'found';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCurrentManagingContracts, getCurrentManagingCustomerAccount, getLoggedInUser, getResource } from '@ucentric/redux';
import { withI18n } from 'react-i18next';
import { url } from '@ucentric/utils';

import config from 'config';
import styles from './styles.module.css';
import { RootState } from 'app/redux/store';
import { SiderMenuOuterProps, SiderMenuProps } from './properties';

class SiderMenu extends React.PureComponent<SiderMenuProps> {
  private get menuItems() {
    const { contracts, currentManagingCustomerAccount, loggedInUser, referrerCreditsScheme, t } = this.props;

    if (!loggedInUser || !loggedInUser.customerAccount || !currentManagingCustomerAccount) {
      return [];
    }

    const isNoncustomer =
      !loggedInUser.customerAccount.categories.includes(AccountCategory.Customer) &&
      !loggedInUser.customerAccount.categories.includes(AccountCategory.ContactPerson);
    const isManagingOtherAccount = currentManagingCustomerAccount['@id'] !== loggedInUser.customerAccount['@id'];
    const isCommercial = contracts.some((contract) => contract.type === ContractType.Commercial);

    return [
      {
        hidden: isNoncustomer,
        icon: 'home',
        key: '/',
        title: t('common:dashboard'),
        to: '/',
      },
      {
        hidden: isNoncustomer,
        icon: 'team',
        key: '/contracts',
        title: t('common:contract_plural'),
        to: '/contracts',
      },
      {
        disabled: isCommercial,
        hidden: isNoncustomer,
        icon: 'file-text',
        key: '/applications',
        title: t('common:application_plural'),
        to: '/applications',
      },
      {
        disabled: isManagingOtherAccount || isCommercial,
        hidden: !config.ENABLE_AFFILIATE,
        icon: 'shopping-cart',
        key: '/shop',
        title: t('common:shop'),
        to: '/shop',
      },
      {
        disabled: isCommercial,
        hidden: !config.ENABLE_AFFILIATE && !config.ENABLE_LOYALTY,
        icon: 'gift',
        key: '/rewards',
        title: t('common:rewards'),
        to: '/rewards',
      },
      {
        disabled: isManagingOtherAccount || !referrerCreditsScheme || isCommercial,
        hidden: !config.ENABLE_REFERRAL_PROGRAM,
        icon: 'tag',
        key: '/invite',
        title: t('common:inviteFriends', { context: config.PROFILE }),
        to: '/invite',
      },
      {
        disabled: !config.ENABLE_TICKET || isManagingOtherAccount,
        hidden: !config.WEB.ENABLE_TICKET,
        icon: 'customer-service',
        key: '/cases',
        title: t('common:contactUs'),
        to: '/cases',
      },
      // {
      //   // disabled: config.PROFILE !== 'iswitch' && config.PROFILE !== 'genting',
      //   hidden: isNoncustomer,
      //   icon: 'info-circle-o',
      //   key: '/help',
      //   title: t('common:help'),
      //   to: '/help',
      // },
    ];
  }

  public render() {
    const {
      currentManagingCustomerAccount,
      defaultNS,
      dispatch,
      i18nOptions,
      location,
      loggedInUser,
      referrerCreditsScheme,
      reportNS,
      t,
      tReady,
      ...props
    } = this.props;

    return (
      <Layout.Sider className={styles.root} width={255} {...props}>
        <div className={styles.logoPanel}>
          <Link to="/">
            <img alt={config.COMPANY.NAME} src={config.COMPANY.LOGO_URL} className={styles.logo} />
          </Link>
        </div>

        <Menu key="sider-menu" mode="inline" selectedKeys={url.pathnameToPathnames(location.pathname)} className={styles.menu}>
          {this.menuItems
            .filter((menuItem) => !menuItem.hidden)
            .map((menuItem) => (
              <Menu.Item disabled={menuItem.disabled || !menuItem.to} key={menuItem.key}>
                <Link to={menuItem.to}>
                  {menuItem.icon && <Icon type={menuItem.icon} />} <span>{menuItem.title}</span>
                </Link>
              </Menu.Item>
            ))}
        </Menu>

        {/* <div className={styles.footerLogoPanel}>
          <Link to="/">
            <img alt={config.COMPANY.NAME} src={config.DATA_PROTECTION_LOGO} className={styles.logo} />
          </Link>
        </div> */}
      </Layout.Sider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  contracts: getCurrentManagingContracts(state),
  currentManagingCustomerAccount: getCurrentManagingCustomerAccount(state),
  location: state.found.match.location,
  loggedInUser: getLoggedInUser(state),
  referrerCreditsScheme: getResource<ReferralCreditsScheme>(state, 'referralCreditsSchemes', { isBasedOn: null, schemeId: 'RF' }),
});

export default compose<SiderMenuProps, SiderMenuOuterProps>(
  connect(mapStateToProps),
  withI18n(),
  React.memo,
)(SiderMenu);
