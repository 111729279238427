/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import moment from 'moment';
import cx from 'clsx';
import { AccountType, CustomerAccountStatus } from '@ucentric/constants';
import { Button, Divider, Drawer, Icon, List } from 'antd';
import { LoadingPanel } from '@ucentric/react-components';
import { apiFetch, getCurrentManagingCustomerAccount, getLoggedInUser, getRelationships } from '@ucentric/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formatter, url as urlHelper, apiResourceValidation } from '@ucentric/utils';
import { get, maxBy } from 'lodash';

import CustomerAccountContractSummaryPanel from 'app/components/commons/CustomerAccountContractSummaryPanel';
import styles from './styles.module.css';
import { Account, SwitchAccountDrawerOuterProps, SwitchAccountDrawerProps } from './properties';
import { setSelectedAccount } from 'app/redux/modules/switch-account/actions';

export const SwitchAccountDrawer = ({
  action,
  status,
  myAccount,
  onChange,
  otherAccounts,
  ...props
}: SwitchAccountDrawerProps) => {
  const handleSwitchAccountClick = (customerAccountIri: string) => {
    action.setSelectedAccount(customerAccountIri);

    if (onChange) {
      onChange(customerAccountIri);
    }
  };

  const isLoggedInUserActive = (): boolean => {
    if (!!myAccount) {
      if (myAccount.contracts && myAccount.contracts.length > 0 && myAccount.contracts.some((contract) => !contract.endDate)) {
        return true;
      } else {
        const recentlyEndedContract: Contract | undefined = maxBy(myAccount.contracts, 'endDate');

        if (!!recentlyEndedContract) {
          return !!recentlyEndedContract.endDate ? moment().isAfter(moment(recentlyEndedContract.endDate).add(90, 'days')) : true;
        }
      }
    }

    return false;
  };

  React.useEffect(() => {
    if (!!myAccount && !!get(myAccount, 'customerAccount.relationships')) {
      myAccount.customerAccount.relationships.forEach((relationshipIri) => {
        action.apiFetch(relationshipIri, undefined, { subrequests: ['to'] });
      });
    }
  }, []);

  return (
    <Drawer className={styles.root} {...props}>
      <h1>Switch Account</h1>

      <Divider />

      {status.pending > 0 && <LoadingPanel />}

      <>
        {!!myAccount && apiResourceValidation.isCustomerAccountActive(myAccount.customerAccount) && (
          <section className={cx(styles.section, { [styles.invisible]: status.pending > 0 })}>
            <h2>My Account</h2>

            <List
              size="large"
              bordered
              itemLayout="vertical"
              dataSource={[myAccount]}
              renderItem={({ contracts, customerAccount, isSelected }: Account) => (
                <List.Item
                  actions={[
                    <Button
                      size="large"
                      key="button"
                      type="primary"
                      onClick={() => handleSwitchAccountClick(customerAccount['@id'])}
                    >
                      Manage This Account
                    </Button>,
                  ]}
                  extra={isSelected ? <Icon type="check" className={styles.icon} /> : undefined}
                >
                  <List.Item.Meta
                    description={<CustomerAccountContractSummaryPanel contracts={contracts} key={customerAccount['@id']} />}
                  />
                </List.Item>
              )}
            />
          </section>
        )}

        {otherAccounts.length > 0 && (
          <section className={cx(styles.section, { [styles.invisible]: status.pending > 0 })}>
            <h2>Other Accounts</h2>

            <List
              size="large"
              bordered
              itemLayout="vertical"
              dataSource={otherAccounts}
              renderItem={({ contracts, customerAccount, isSelected }: Account) => (
                <List.Item
                  actions={[
                    <Button
                      size="large"
                      key="button"
                      type="primary"
                      onClick={() => handleSwitchAccountClick(customerAccount['@id'])}
                    >
                      Manage This Account
                    </Button>,
                  ]}
                  extra={isSelected ? <Icon type="check" className={styles.icon} /> : undefined}
                >
                  <List.Item.Meta
                    title={
                      <span>
                        {customerAccount.type === AccountType.Individual
                          ? formatter.formatName(customerAccount.personDetails)
                          : customerAccount.corporationDetails.name}
                        's Account
                      </span>
                    }
                    description={<CustomerAccountContractSummaryPanel contracts={contracts} key={customerAccount['@id']} />}
                  />
                </List.Item>
              )}
            />
          </section>
        )}
      </>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  const loggedInUser = getLoggedInUser(state);
  const loggedInUserRelationships: CustomerAccountRelationship[] = !!loggedInUser
    ? (getRelationships(state) as CustomerAccountRelationship[])
    : [];
  const currentManagingCustomerAccount = getCurrentManagingCustomerAccount(state);

  const myAccount = loggedInUser
    ? {
        contracts: loggedInUser.customerAccount.contracts,
        customerAccount: loggedInUser.customerAccount,
        isSelected: urlHelper.irify(currentManagingCustomerAccount) === urlHelper.irify(loggedInUser.customerAccount),
      }
    : null;
  const otherAccounts: Account[] = loggedInUserRelationships
    .map((relationship) => ({
      contracts: relationship.contracts,
      customerAccount: relationship.to,
      isSelected: urlHelper.irify(currentManagingCustomerAccount) === urlHelper.irify(relationship.to),
    }))
    .filter((account) => !!account.customerAccount);

  return {
    myAccount,
    otherAccounts,
    status: state.statuses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiFetch,
      setSelectedAccount,
    },
    dispatch,
  ),
});

export default compose<SwitchAccountDrawerProps, SwitchAccountDrawerOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SwitchAccountDrawer);
