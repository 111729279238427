import { Priority, Source, TicketStatus } from '@ucentric/constants';

const todayDate = new Date().toISOString();

export const TICKET_FORM_INITIAL_VALUES = {
  channel: Source.SelfServicePortal,
  plannedCompletionDate: undefined,
  priority: Priority.Medium,
  source: Source.SelfServicePortal,
  startDate: todayDate,
  status: TicketStatus.New,
  supplementaryFiles: [],
};
