import * as React from 'react';
import flatten, { unflatten } from 'flat';
import { Button } from 'antd';
import { connect, Form } from 'formik';
import { mapValues, merge } from 'lodash';

import styles from './styles.module.css';
import { PageFormProps } from './properties';
import { TicketFormStep } from '../../constants';

class PageForm extends React.Component<PageFormProps> {
  public render() {
    const { children, className, currentStep, onPrevClick, title, disabled, isSubmitting } = this.props;

    return (
      <Form className={className} onSubmit={this.handleSubmit}>
        <h1 className={styles.title}>{title}</h1>

        <div>{children}</div>

        <footer className={styles.footer}>
          {currentStep !== TicketFormStep.TicketDetails && (
            <Button
              className={styles.button}
              disabled={currentStep === TicketFormStep.TicketDetails || disabled}
              onClick={onPrevClick}
              size="large"
            >
              Previous
            </Button>
          )}

          <Button
            className={styles.button}
            htmlType="submit"
            type="primary"
            size="large"
            disabled={disabled}
            loading={isSubmitting}
          >
            {currentStep === TicketFormStep.Summary ? 'Submit' : 'Next'}
          </Button>
        </footer>
      </Form>
    );
  }

  private handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.persist();

    // @todo performance improvements!
    const { errors, touched, values } = this.props.formik;
    const invalidValues = unflatten(mapValues(flatten(errors), () => undefined));
    const initialValues = this.props.initialValues;

    // Be careful when using `merge`, it will overwrite the source
    const finalTouched = merge({}, initialValues, initialValues, touched);
    const finalValues = merge({}, initialValues, invalidValues, values);

    this.props.formik.setFormikState(
      {
        touched: finalTouched,
        values: finalValues,
      },
      () => {
        if (this.props.onSubmit) {
          this.props.onSubmit(ev);
        } else {
          this.props.formik.handleSubmit(ev);
        }
      },
    );
  };
}

export default connect(PageForm);
