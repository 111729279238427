/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Mengky Chen <mengky.chen@sisgroup.sg>
 */

import * as React from 'react';
import { Card, Steps } from 'antd';
import { DigitalDocumentArrayFieldset, TicketDetailsFieldset } from '@ucentric/react-components';
import { compose } from 'recompose';
import { connect, Formik } from 'formik';
import { withI18n } from 'react-i18next';

import PageForm from './components/PageForm';
import TicketSummaryFieldset from './components/TicketSummaryFieldset';
import styles from './styles.module.css';
import { TicketFormOuterProps, TicketFormProps, TicketFormState } from './properties';
import { TicketFormStep } from './constants';
import { ticketSchema } from './validation-schema';

const Subform = compose(connect)(({ customerAccountIri, translationOptions, type }) => {
  const formTypes = [
    {
      component: (
        <TicketDetailsFieldset
          translationOptions={translationOptions}
          ineligibleFields={['priority', 'source', 'assignee', 'parent', 'resolutionOfficer', 'type', 'channel']}
          customerAccountIri={customerAccountIri}
          displayTicketCategoryCode={false}
        />
      ),
    },
    {
      component: <DigitalDocumentArrayFieldset listType="picture" name="supplementaryFiles" />,
    },
    {
      component: <TicketSummaryFieldset translationOptions={translationOptions} />,
    },
  ];

  return formTypes[type].component;
});

class TicketForm extends React.Component<TicketFormProps, TicketFormState> {
  public state = {
    currentStep: TicketFormStep.TicketDetails,
  };

  private get subforms() {
    return [
      {
        initialValues: {},
        title: this.props.t('form:ticket.infoDetailsTitle'),
        validationSchema: ticketSchema({ translationOptions: this.props.translationOptions }),
      },
      {
        initialValues: {},
        title: this.props.t('form:ticket.attachmentsTabTitle'),
      },
      {
        initialValues: {},
        title: this.props.t('form:ticket.summaryTitle'),
      },
    ];
  }

  public render() {
    const { formik, translationOptions, isSubmitting } = this.props;
    const { currentStep } = this.state;
    return (
      <Card
        className={styles.root}
        title={
          <Steps current={currentStep}>
            {this.subforms.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        }
      >
        <Formik
          enableReinitialize
          initialValues={formik.values}
          onSubmit={(values) => {
            if (currentStep === TicketFormStep.Summary) {
              formik.submitForm();
            } else {
              formik.setValues({ ...formik.values, ...values });
              this.handleNextStepClick();
            }
          }}
          render={() => (
            <PageForm
              className={styles.content}
              currentStep={currentStep}
              onPrevClick={this.handlePrevStepClick}
              title={this.subforms[currentStep].title}
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
            >
              <Subform
                customerAccountIri={formik.values.customer['@id']}
                translationOptions={translationOptions}
                type={currentStep}
              />
            </PageForm>
          )}
          validationSchema={this.subforms[currentStep].validationSchema}
        />
      </Card>
    );
  }

  private handleNextStepClick = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  private handlePrevStepClick = () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
  };
}

export default compose<TicketFormProps, TicketFormOuterProps>(
  connect,
  withI18n(),
)(TicketForm);
