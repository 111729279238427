import * as React from 'react';
import { ApiRoute } from '@ucentric/constants';
import { Formik } from 'formik';
import { Icon, Modal } from 'antd';
import { Link } from 'found';
import { apiCreate, apiFetch, getLoggedInUser, getResource, getCurrentManagingCustomerAccount } from '@ucentric/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { upperFirst } from 'lodash';
import { withI18n } from 'react-i18next';

import TicketForm from 'app/components/forms/TicketForm';
import styles from './styles.module.css';
import { RootState } from 'app/redux/store';
import { TICKET_FORM_INITIAL_VALUES } from './constants';
import { TicketDetailsPageOuterProps, TicketDetailsPageProps } from './properties';

class TicketDetailsPage extends React.Component<TicketDetailsPageProps> {
  public componentDidMount() {
    if (this.props.location) {
      this.props.action.apiFetch(ApiRoute.TicketType, {
        itemsPerPage: 1,
        name: this.props.location.query.type,
      });
    }
  }
  public render() {
    const { action, location, loggedInCustomer, t, ticketTypeIri } = this.props;

    return (
      <div className={styles.root}>
        <header className={styles.header}>
          <Link to="/cases">
            <Icon type="arrow-left" /> {t('common:back')}
          </Link>
        </header>

        <Formik
          enableReinitialize
          initialValues={{
            ...TICKET_FORM_INITIAL_VALUES,
            customer: { ...loggedInCustomer, addresses: [] },
            type: ticketTypeIri,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { ...ticketForm } = values;
            const cleanedTicketForm = { ...ticketForm, customer: ticketForm.customer['@id'] };

            try {
              const ticket: Ticket = await action.apiCreate(ApiRoute.Ticket, cleanedTicketForm);

              // Congratulation!
              Modal.success({
                content: `Your feedback has been received (case reference number ${
                  ticket.ticketNumber
                }) and we shall be responding to you within the next 3 business days.`,
                onOk: () => {
                  this.props.router.push('/cases');
                },
                title: 'Thank you for writing in to us',
              });
            } catch (error) {
              Modal.error({
                content: 'Something went wrong, please contact our support team',
              });
            } finally {
              setSubmitting(false);
            }
          }}
          render={(formikProps) => {
            return (
              <TicketForm
                translationOptions={{ context: 'web', type: location.query.type }}
                isSubmitting={formikProps.isSubmitting}
              />
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, { location: { query } }: TicketDetailsPageProps) => {
  const ticketType = getResource<TicketType>(state, 'ticketTypes', { name: upperFirst(query.type) });
  const currentUser: User = getLoggedInUser(state);
  const currentManagingCustomerAccount: CustomerAccount = getCurrentManagingCustomerAccount(state);
  return {
    loggedInCustomer: currentManagingCustomerAccount ? currentManagingCustomerAccount : currentUser.customerAccount,
    ticketTypeIri: ticketType ? ticketType['@id'] : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  action: bindActionCreators(
    {
      apiCreate,
      apiFetch,
    },
    dispatch,
  ),
});

export default compose<TicketDetailsPageProps, TicketDetailsPageOuterProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withI18n(),
)(TicketDetailsPage);
