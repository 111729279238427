/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import loadable from 'loadable-components';
import { Logout } from '@ucentric/react-components';
import { hotRouteConfig, makeRouteConfig, Route } from 'found';

import App from 'app/components/layouts/App';
import AuthorizedRoute from 'app/components/commons/AuthorizedRoute';
import CustomerCategoryCheckRoute from 'app/components/commons/CustomerCategoryCheckRoute';
import TicketDetailsPage from 'app/pages/TicketDetailsPage';
import TicketListPage from 'app/pages/TicketListPage';
import {
  fetchAffiliatePrograms,
  fetchContract,
  fetchContracts,
  fetchInvitePage,
  fetchLoggedInUserCustomerAccount,
  fetchOfferCatalog,
  fetchTicket,
  registerLogin,
} from 'app/helpers/router-fetch';
import config from 'config';

const AffiliateProgramListPage = loadable(() => import('app/pages/AffiliateProgramListPage'));
const ApplicationRequestListPage = loadable(() => import('app/pages/ApplicationRequestListPage'));
const SignOnBehalfApprovalPage = loadable(() => import('app/pages/SignOnBehalfApprovalPage'));
const ConsumptionCalculatorPage = loadable(() => import('app/pages/ConsumptionCalculatorPage'));
const ContactPage = loadable(() => import('app/pages/ContactPage'));
const ContractDetailsPage = loadable(() => import('app/pages/ContractDetailsPage'));
const ContractListPage = loadable(() => import('app/pages/ContractListPage'));
const CreditsListPage = loadable(() => import('app/pages/CreditsListPage'));
const CustomerDetailsPage = loadable(() => import('app/pages/CustomerDetailsPage'));
const DashboardPage = loadable(() => import('app/pages/DashboardPage'));
const EnergyConsumptionCalculatorPage = loadable(() => import('app/pages/EnergyConsumptionCalculatorPage'));
const EnergyConsumptionComparisonPage = loadable(() => import('app/pages/EnergyConsumptionComparisonPage'));
const ForgotPasswordPage = loadable(() => import('app/pages/ForgotPasswordPage'));
const HelpPage = loadable(() => import('app/pages/HelpPage'));
const InvitePage = loadable(() => import('app/pages/InvitePage'));
const MaintenanceMessagePage = loadable(() => import('app/pages/MaintenanceMessagePage'));
const PaymentOptionsPage = loadable(() => import('app/pages/PaymentOptionsPage'));
const LandingPage = loadable(() => import('app/pages/LandingPage'));
const MoneyCreditsDetailsPage = loadable(() => import('app/pages/MoneyCreditsDetailsPage'));
const NewApplicationRequestPage = loadable(() => import('app/pages/NewApplicationRequestPage'));
const PointCreditsDetailsPage = loadable(() => import('app/pages/PointCreditsDetailsPage'));
const OfferCatalogListPage = loadable(() => import('app/pages/OfferCatalogListPage'));
const OfferCatalogDetailsPage = loadable(() => import('app/pages/OfferCatalogDetailsPage'));
const OfferInputSelectPage = loadable(() => import('app/pages/OfferInputSelectPage'));
const ProfilePage = loadable(() => import('app/pages/ProfilePage'));
const ResetPasswordPage = loadable(() => import('app/pages/ResetPasswordPage'));
const UserEmailConfirmPage = loadable(() => import('app/pages/UserEmailConfirmPage'));
const UserUnsubscribePage = loadable(() => import('app/pages/UserUnsubscribePage'));

const routeConfig = hotRouteConfig(
  makeRouteConfig(
    <Route>
      <Route Component={UserEmailConfirmPage} path="/update-email" />
      <Route Component={UserUnsubscribePage} path="/user-unsubscribe" />
      <Route Component={OfferInputSelectPage} path="/offer-input" />
      <Route Component={SignOnBehalfApprovalPage} path="/sign-on-behalf-approval" />
      <Route Component={ForgotPasswordPage} path="/forgot-password" />
      <Route Component={ResetPasswordPage} path="/reset-password" />
      <Route Component={LandingPage} getData={registerLogin()} path="/auth" />
      <Route Component={(props) => {
        if (config.LOGIN_APP_URL) {
          window.location.href = config.LOGIN_APP_URL;
          return null;
        }
        return <LandingPage {...props}/>;
      }} path="/login" />
      <Route render={(props) => <Logout {...props} platform="web" />} path="/logout" />
      <Route Component={MaintenanceMessagePage} path="/maintenance-message" />
      <AuthorizedRoute Component={App} path="/">
        {/* @todo do we have cleaner way of handling this? */}
        <CustomerCategoryCheckRoute>
          <Route Component={DashboardPage} />
          <Route Component={EnergyConsumptionComparisonPage} path="consumption-comparison" />
          <Route Component={ConsumptionCalculatorPage} path="consumption" />
          <Route path="contracts">
            <Route Component={ContractListPage} />
            <Route Component={ContractDetailsPage} path=":contractNumber" />
            <Route Component={CustomerDetailsPage} getData={fetchContract} path=":contractNumber/customer" />
          </Route>
          <Route path="applications">
            <Route Component={ApplicationRequestListPage} />
            <Route Component={NewApplicationRequestPage} path="create" />
          </Route>
          <Route Component={AffiliateProgramListPage} getData={fetchAffiliatePrograms()} path="shop" />
          <Route path="consumption-calculation">
            <Route Component={EnergyConsumptionCalculatorPage} />
          </Route>
          <Route path="rewards">
            <Route Component={CreditsListPage} />
            <Route path="cash" getData={fetchContracts()} Component={MoneyCreditsDetailsPage} />
            <Route path="points" Component={PointCreditsDetailsPage} />
          </Route>
          <Route path="product-catalogs">
            <Route Component={OfferCatalogListPage} />
            <Route Component={OfferCatalogDetailsPage} getData={fetchOfferCatalog()} path=":id" />
          </Route>
          <Route Component={ProfilePage} path="profile" />
          <Route Component={InvitePage} getData={fetchInvitePage} path="invite" />
          <Route path="payment">
            <Route Component={PaymentOptionsPage} path=":contractNumber" getData={fetchContract} />
          </Route>
          <Route path="cases">
            <Route Component={TicketListPage} />
            <Route Component={TicketDetailsPage} getData={fetchLoggedInUserCustomerAccount} path="create" />
          </Route>
          <Route Component={ContactPage} path="contact" />
          <Route Component={HelpPage} path="help" />
        </CustomerCategoryCheckRoute>
      </AuthorizedRoute>
    </Route>,
  ),
);

export default routeConfig;
