/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import { AuthorizedRoute as BaseAuthorizedRoute } from '@ucentric/react-components';

import { fetchInvitePage, fetchLoggedInUserCustomerAccount } from 'app/helpers/router-fetch';

class AuthorizedRoute extends BaseAuthorizedRoute {
  public fetchLoggedInUserCustomerAccount = (props) => {
    return Promise.all([fetchLoggedInUserCustomerAccount(props), fetchInvitePage(props)]);
  };
}

export default AuthorizedRoute;
