/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 *
 * @author Lee Siong Chan <leesiong.chan@sisgroup.sg>
 */

import * as React from 'react';
import cx from 'clsx';
import { Drawer, Dropdown, Icon, Menu, Tooltip } from 'antd';
import { Link, withRouter } from 'found';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useToggle } from '@ucentric/react-components';
import { withI18n } from 'react-i18next';

import SiderMenu from 'app/components/layouts/SiderMenu';
import config from 'config';
import styles from './styles.module.css';
import { MainHeaderOuterProps, MainHeaderProps } from './properties';
import { NotificationIcon } from 'app/components/commons';
import { RootState } from 'app/redux/store';

export const MainHeader = ({ loggedInUser, collapsed, onCollapseChange, onSwitchAccountClick, t }: MainHeaderProps) => {
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const menu = (
    <Menu selectedKeys={[]} className={styles.dropDown}>
      <Menu.Item>
        <Link to="/profile">
          <Icon type="user" /> Profile
        </Link>
      </Menu.Item>
      <Menu.Item>
        <span className={styles.link} onClick={onSwitchAccountClick}>
          <Icon type="team" /> Switch Account
        </span>
      </Menu.Item>

      {config.PROFILE !== 'iswitch' && (
        <Menu.Item disabled>
          <Icon type="setting" /> Settings
        </Menu.Item>
      )}

      <Menu.Divider className={styles.hideOnMobile} />
      <Menu.Item>
        <Link to="/logout">
          <Icon type="logout" /> Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const handleMenuToggle = (toggle: boolean) => {
    toggleMenu(toggle);
  };

  return (
    <div className={styles.root}>
      <div className={cx(styles.menu, styles.simple)}>
        <div>
          <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={onCollapseChange} />
        </div>

        <div>
          <Link to="/help">
            <Tooltip title={t('common:help')}>
              <span className={styles.action}>
                <Icon type="question-circle-o" />
              </span>
            </Tooltip>
          </Link>

          <span className={styles.action}>
            <NotificationIcon />
          </span>

          {loggedInUser && (
            <Dropdown overlay={menu}>
              <span className={styles.action}>{loggedInUser.username || loggedInUser.email}</span>
            </Dropdown>
          )}
        </div>
      </div>

      {/* Mobile! */}
      <div className={cx(styles.menu, styles.full)}>
        <div>
          <Link to="/">
            <img alt={config.COMPANY.NAME} src={config.COMPANY.LOGO_URL} className={styles.logo} />
          </Link>
        </div>

        <div>
          <Icon
            className={styles.trigger}
            type={collapsed ? 'menu-fold' : 'menu-unfold'}
            onClick={() => handleMenuToggle(true)}
          />

          <Drawer placement="right" width="280" closable={false} onClose={() => handleMenuToggle(false)} visible={isMenuOpen}>
            <div className={styles.wrapProfile}>
              <div className={styles.headerTitle}>
                <span>Profile</span>
              </div>

              {loggedInUser && <span className={styles.userName}>{loggedInUser.username || loggedInUser.email}</span>}

              {menu}

              <div className={cx(styles.headerTitle, styles.profile)}>
                <span>Menu</span>
              </div>

              <SiderMenu />
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loggedInUser: state.auth,
});

export default compose<MainHeaderProps, MainHeaderOuterProps>(
  connect(mapStateToProps),
  withI18n(),
  withRouter,
  React.memo,
)(MainHeader);
