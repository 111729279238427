import moment from 'moment';
import i18next, { TranslationOptions } from 'i18next';
import yup from '@ucentric/validator';

export const ticketSchema = ({ translationOptions }: { translationOptions?: TranslationOptions } = {}) =>
  yup.object().shape({
    category: yup
      .string()
      .label(i18next.t('form:ticket.category.label', translationOptions))
      .required(),
    description: yup
      .string()
      .label(i18next.t('form:ticket.description.label', translationOptions))
      .nullable(),
    startDate: yup
      .date()
      .label(i18next.t('form:ticket.dateOpened.label', translationOptions))
      .format(moment.HTML5_FMT.DATE)
      .nullable()
      .required(),
    subcategory: yup
      .string()
      .label(i18next.t('form:ticket.subcategory.label', translationOptions))
      .nullable()
      .required(),
    type: yup
      .string()
      .label(i18next.t('form:ticket.type.label', translationOptions))
      .required(),
  });
