/**
 * This file is part of the U-Centric project.
 *
 * (c) U-Centric Development Team <dev@ucentric.sisgroup.sg>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import * as React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { compose } from 'recompose';
import { withI18n } from 'react-i18next';
import { connect as formikConnect } from 'formik';
import { connect } from 'react-redux';
import { getResource } from '@ucentric/redux';

import config from 'config';
import { RootState } from 'app/redux/store';
import { TicketSummaryTableOuterProps, TicketSummaryTableProps } from './properties';

/**
 * @author Mengky Chen <mengky.chen@sisgroup.sg>
 */
export class TicketSummaryTable extends React.PureComponent<TicketSummaryTableProps> {
  private get columns() {
    return [{ dataIndex: 'label' }, { dataIndex: 'value' }];
  }

  private get data() {
    const { contract, formik, ticketMainCategory, ticketSubcategory, translationOptions, t } = this.props;
    return [
      {
        label: t('form:ticket.category.label', translationOptions),
        value:
          ticketMainCategory &&
          `${ticketMainCategory.code ? `${ticketMainCategory.code} - ${ticketMainCategory.name}` : ticketMainCategory.name}`,
      },
      {
        label: t('form:ticket.subcategory.label', translationOptions),
        value:
          ticketSubcategory &&
          `${ticketSubcategory.code ? `${ticketSubcategory.code} - ${ticketSubcategory.name}` : ticketSubcategory.name}`,
      },
      {
        label: t('form:ticket.contract.label', translationOptions),
        value: contract && contract.contractNumber,
      },
      {
        label: t('form:ticket.description.label', translationOptions),
        value: formik.values.description,
      },
      {
        label: t('form:ticket.dateOpened.label', translationOptions),
        value: moment(formik.values.dateOpened).format(config.DATE_FORMAT),
      },
    ];
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.data}
        pagination={false}
        rowKey="label"
        {...this.props}
        bordered
        showHeader={false}
        title={() => 'Details'}
      />
    );
  }
}

const mapStateToProps = (state: RootState, { formik }: TicketSummaryTableOuterProps) => {
  return {
    contract: getResource(state, 'contracts', { '@id': formik.values.contract }),
    ticketMainCategory: getResource(state, 'ticketCategories', { '@id': formik.values.category }),
    ticketSubcategory: getResource(state, 'ticketCategories', { '@id': formik.values.subcategory }),
  };
};

export default compose<TicketSummaryTableProps, TicketSummaryTableOuterProps>(
  formikConnect,
  connect(mapStateToProps),
  withI18n(),
)(TicketSummaryTable);
